/* === Fira Sans - regular */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('Fira Sans Regular'), local('FiraSans-Regular'), url(/asset/fonts/FiraSans-Regular.8d4eebb33f68cfabfff42f9cd082b3b0.woff) format('woff'), url(/asset/fonts/FiraSans-Regular.8705f937b9f572f8e42072cd818c6a60.woff2) format('woff2');
}

/* === Fira Sans - 500 */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;

  src: local('Fira Sans Medium'), local('FiraSans-Medium'), url(/asset/fonts/FiraSans-Medium.c2793ea4e68fa4e48bc0574a6175840e.woff) format('woff'), url(/asset/fonts/FiraSans-Medium.b1a486bad5ab273230ff01539177eaad.woff2) format('woff2');
}

/* === Source Sans Pro - regular */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(/asset/fonts/SourceSansPro-Regular.d683bfb394f888c5820ce11fc4770fd8.woff) format('woff'), url(/asset/fonts/SourceSansPro-Regular.80c998aa03640281d556814a14e1d9c8.woff2) format('woff2');
}

/* === Clan Pro - 500 */
@font-face {
  font-family: 'ClanPro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;

  src: local('ClanPro-Bold'), local('ClanPro-Bold'), url(/asset/fonts/ClanPro-Bold.3b8cd8710560982ccc0222acab901e75.woff) format('woff'), url(/asset/fonts/ClanPro-Bold.32e33966e2332ed1650baac9ecb5f16f.woff2) format('woff2');
}

/* === Clan Pro News - regular */
@font-face {
  font-family: 'ClanPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('ClanPro-News'), local('ClanPro-News'), url(/asset/fonts/ClanPro-News.1afcd6a048104a89bd977ec19e38b364.woff) format('woff'), url(/asset/fonts/ClanPro-News.0f60c03dba33549ff47458b95131fd00.woff2) format('woff2');
}
